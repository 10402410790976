import {apiRequest} from './base'

//获取可选风暴标签
export function postCrispsListTags(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/list-tags',req,onOk,onFail,eventually,headers)
}
//搜索标签
export function postCrispsSearchTag(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/search-tag',req,onOk,onFail,eventually,headers)
}
//新建标签
export function postCrispsNewTag(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/new-tag',req,onOk,onFail,eventually,headers)
}
//分享我的导图到风暴
export function postCrispsShare(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/share',req,onOk,onFail,eventually,headers)
}
//分享群组文件到风暴
export function postCrispsGmapToCrisps(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/gmap-to-crisps',req,onOk,onFail,eventually,headers)
}
//删除风暴
export function postCrispsDelete(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/delete',req,onOk,onFail,eventually,headers)
}
//收藏风暴
export function postCrispsCollect(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/collect',req,onOk,onFail,eventually,headers)
}
//取消收藏风暴
export function postCrispsUncollect(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/uncollect',req,onOk,onFail,eventually,headers)
}
//关注标签
export function postCrispsFollowTag(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/follow-tag',req,onOk,onFail,eventually,headers)
}
//取消关注
export function postCrispsUnfollowTag(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/unfollow-tag',req,onOk,onFail,eventually,headers)
}
//发起评论
export function postCrispsComment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/comment',req,onOk,onFail,eventually,headers)
}
//点赞评论
export function postCrispsVoteComment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/vote-comment',req,onOk,onFail,eventually,headers)
}
//取消点赞评论
export function postCrispsUnvoteComment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/unvote-comment',req,onOk,onFail,eventually,headers)
}
//删除评论
export function postCrispsDeleteComment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/delete-comment',req,onOk,onFail,eventually,headers)
}
//举报评论
export function postCrispsReportComment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/report-comment',req,onOk,onFail,eventually,headers)
}
//点赞风暴
export function postCrispsVoteCrisps(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/vote-crisps',req,onOk,onFail,eventually,headers)
}
//取消点赞风暴
export function postCrispsUnvoteCrisps(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/unvote-crisps',req,onOk,onFail,eventually,headers)
}
//举报风暴
export function postCrispsReportCrisps(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/report-crisps',req,onOk,onFail,eventually,headers)
}
//不感兴趣
export function postCrispsNotInterested(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/not-interested',req,onOk,onFail,eventually,headers)
}
//修改推荐页顶部下拉-标签管理面板数据
export function postCrispsModifyDropdownTags(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/modify-dropdown-tags',req,onOk,onFail,eventually,headers)
}
//首页数据
export function postCrispsHomeData(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/home-data',req,onOk,onFail,eventually,headers)
}
//拉取最新推荐列表-旧
export function postCrispsPullFeed(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/pull-feed',req,onOk,onFail,eventually,headers)
}
//拉取底部推荐列表-旧
export function postCrispsQueryFeed(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/query-feed',req,onOk,onFail,eventually,headers)
}
//推荐页顶部下拉标签管理面板-旧
export function postCrispsDropdownTagPage(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/dropdown-tag-page',req,onOk,onFail,eventually,headers)
}
//查看风暴
export function postCrispsView(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/view',req,onOk,onFail,eventually,headers)
}
//使用风暴
export function postCrispsUse(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/use',req,onOk,onFail,eventually,headers)
}
//查看风暴下的全部评论
export function postCrispsQueryComments(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/query-comments',req,onOk,onFail,eventually,headers)
}
//拉取沸点首页数据
export function postCrispsBoilingHome(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/boiling-home',req,onOk,onFail,eventually,headers)
}
//获取搜索热词列表
export function postCrispsListHotSearch(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/list-hot-search',req,onOk,onFail,eventually,headers)
}
//搜索风暴
export function postCrispsSearch(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/search',req,onOk,onFail,eventually,headers)
}
//标签主页数据
export function postCrispsGetTagPage(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/get-tag-page',req,onOk,onFail,eventually,headers)
}
//拉取风暴的分页列表
export function postCrispsListCsheets(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/list-csheets',req,onOk,onFail,eventually,headers)
}
//搜索风暴分页
export function postCrispsSearchCsheet(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/crisps/search-csheet',req,onOk,onFail,eventually,headers)
}
