// import mind.yushu.com.mindmap.core.calcule.Config;
// import mind.yushu.com.mindmap.core.common.IOController;
// import mind.yushu.com.mindmap.core.storage.CacheFileManager;

import Config from "../core/core/calcule/Config"
import CacheFileManager from "../core/core/storage/CacheFileManager"
// import IOController from "../core/core/common/IOController"
/**
 * Created by tony on 2019/12/11.
 */

class Logger {

    log(msg) {
        Log.i("mindmap", msg);
        if (Config.writeLog) {
            this.writeToFile(msg);
        }
    }

    log(throwable) {
        if (throwable == null) {
            return;
        }
    }

    writeToFile(message) {
        var logPath = CacheFileManager.getLogStorePath();
        var dir = new File(logPath);
        var log = new File(dir.getAbsolutePath() + File.separator + this.getFileName());

        var printWriter = null;
        try {
            printWriter = new PrintWriter(new FileWriter(log, true));
            printWriter.print(message);
            printWriter.print("\n");
        } catch (tr2) {
            tr2.printStackTrace();
        } finally {
            if (printWriter != null) {
                printWriter.close();
            }
        }
    }

    // @SuppressLint("SimpleDateFormat")
    getFileName() {
        var df = new SimpleDateFormat("yyyy-MM-dd");
        return df.format(new Date()) + ".txt";
    }
}

export default Logger
